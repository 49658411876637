import _ from 'lodash';

import {
  CalendarBlock,
  DynamicFormFieldAttributes,
  Practice,
  PracticeDevice,
  PracticeInsurancePlan,
  User,
  Verbiages,
  VisitTypeData,
} from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import {
  sanitizeAndTransformInsurancePlansResponse,
  sanitizeAndTransformResponse,
  sanitizeAndTransformResponseData,
} from 'ev-api/common/transformers';
import {
  AppointmentTimesResponse,
  CreateNewPatientFromCheckInResponse,
  CreateNewPatientResponse,
  PracticeLanguageQuickSelectsResponse,
  PracticeServesLocationResponse,
  PracticeTradingPartnerResponse,
  SearchPracticeProvidersResponse,
} from 'ev-api/core';
import {
  practiceCustomVerbiagesTransform,
  practiceDevicesTransform,
  practiceTransform,
  supportedInsuranceFormsTransform,
  visitLinkForDeviceTransform,
} from 'ev-api/core/practices/transformers';
import { userTransform } from 'ev-api/core/users/transformers';
import { ScheduleBlocks } from 'ev-types/schedule-blocks';

import {
  AppointmentTimesParams,
  AssignPracticeDeviceParams,
  CreateCalendarBlockParams,
  CreateNewPatientFromCheckInParams,
  CreateNewPatientParams,
  CreatePracticeDeviceParams,
  DeleteCalendarBlockParams,
  DeletePracticeDeviceParams,
  GetPracticeByIdParams,
  GetPracticesByIdsArray,
  GetVisitLinkForDeviceParams,
  HandleParam,
  LaunchPracticeDeviceParams,
  LoadScheduledBlocksParams,
  PracticeIdParam,
  PracticeServesLocationParams,
  SearchPracticeProvidersParams,
  SetDeviceToInVisitParams,
  UpdateCalendarBlockParams,
  UpdatePracticeDeviceParams,
  UpdatePracticeParams,
  UserSchedulabilityForPracticesParams,
  UserSchedulabilityParams,
} from './params';
import {
  calendarBlockResponseTransform,
  practicesToPracticeTransform,
  practicesTransform,
} from './transformers';

const practicesApi = api.injectEndpoints({
  endpoints: builder => ({
    getPracticeByHandle: builder.query<Practice, HandleParam>({
      query: ({ handle }) => ({
        url: `${Base.V3}/practices/search/${handle}`,
      }),
      transformResponse: practiceTransform,
    }),
    getPracticesById: builder.query<Practice[], GetPracticesByIdsArray>({
      query: ({ ids, lite = false, paginate = undefined }) => ({
        url: `${Base.V3}/practices/bulk_show`,
        method: 'PUT',
        body: { ids, lite, paginate },
      }),
      transformResponse: practicesTransform,
    }),
    /**
     * It is not possible to use the existing getPracticesById query above to
     * to silently update the currentPractice as the isFetchingCurrentPractice
     * flag in commonData would put the entire application in loading mode.
     * So this query was added, which essentially does the same thing as
     * getPracticesById, but silently.
     */
    getPracticeById: builder.query<Practice, GetPracticeByIdParams>({
      query: ({ id }) => ({
        url: `${Base.V3}/practices/bulk_show`,
        method: 'PUT',
        body: { ids: [id], lite: false },
      }),
      transformResponse: practicesToPracticeTransform,
    }),
    updatePractice: builder.mutation<void, UpdatePracticeParams>({
      query: ({ id, practice }) => ({
        method: 'PUT',
        url: `${Base.V2}/practices/${id}`,
        body: {
          practice: practice,
        },
      }),
    }),
    getPracticeCustomVerbiage: builder.query<Verbiages, PracticeIdParam>({
      query: ({ id }) => {
        return {
          url: `${Base.V3}/practices/${id}/practice_custom_verbiages`,
          method: 'GET',
          params: {
            all_langs: true,
          },
        };
      },
      transformResponse: practiceCustomVerbiagesTransform,
    }),
    getPracticeDevices: builder.query<PracticeDevice[], PracticeIdParam>({
      query: ({ id }) => {
        return {
          url: `${Base.V3}/practices/${id}/devices`,
          method: 'GET',
        };
      },
      transformResponse: practiceDevicesTransform,
      providesTags: [Tags.PracticeDevices],
    }),
    getVisitLinkForDevice: builder.query<
      string | undefined,
      GetVisitLinkForDeviceParams
    >({
      query: ({ practiceId, uuid }) => {
        return {
          url: `${Base.V3}/practices/${practiceId}/devices/${uuid}/visit`,
          method: 'GET',
        };
      },
      transformResponse: visitLinkForDeviceTransform,
    }),
    createPracticeDevice: builder.mutation<void, CreatePracticeDeviceParams>({
      query: ({ practiceId, label }) => ({
        method: 'POST',
        url: `${Base.V3}/practices/${practiceId}/devices`,
        body: {
          label,
        },
      }),
      invalidatesTags: [Tags.PracticeDevices],
    }),
    setDeviceToInVisit: builder.mutation<void, SetDeviceToInVisitParams>({
      query: ({ practiceId, uuid }) => ({
        method: 'POST',
        url: `${Base.V3}/practices/${practiceId}/devices/${uuid}/launch_processed`,
      }),
    }),
    updatePracticeDevice: builder.mutation<void, UpdatePracticeDeviceParams>({
      query: ({ practiceId, uuid, label }) => ({
        method: 'PATCH',
        url: `${Base.V3}/practices/${practiceId}/devices/${uuid}`,
        body: {
          label,
        },
      }),
      invalidatesTags: [Tags.PracticeDevices],
    }),
    deletePracticeDevice: builder.mutation<void, DeletePracticeDeviceParams>({
      query: ({ practiceId, uuid }) => ({
        method: 'DELETE',
        url: `${Base.V3}/practices/${practiceId}/devices/${uuid}`,
      }),
      invalidatesTags: [Tags.PracticeDevices],
    }),
    assignPracticeDevice: builder.mutation<void, AssignPracticeDeviceParams>({
      query: ({ practiceId, deviceId }) => ({
        method: 'POST',
        url: `${Base.V3}/practices/${practiceId}/devices/${deviceId}/assign`,
      }),
    }),
    launchPracticeDevice: builder.mutation<void, LaunchPracticeDeviceParams>({
      query: ({ practiceId, deviceId, visitId }) => ({
        method: 'POST',
        url: `${Base.V3}/practices/${practiceId}/devices/${deviceId}/launch`,
        body: {
          visit_id: visitId,
        },
      }),
    }),
    logoutPracticeDevice: builder.mutation<void, AssignPracticeDeviceParams>({
      query: ({ practiceId, deviceId }) => ({
        method: 'POST',
        url: `${Base.V3}/practices/${practiceId}/devices/${deviceId}/logout`,
      }),
    }),
    getSupportedInsuranceDynamicFields: builder.query<
      Record<string, DynamicFormFieldAttributes>,
      PracticeIdParam
    >({
      query: ({ id }) => {
        return {
          url: `${Base.V3}/practices/${id}/supported_insurance_forms/get_current`,
          method: 'GET',
        };
      },
      providesTags: [Tags.SupportedInsuranceDynamicFields],
      transformResponse: supportedInsuranceFormsTransform,
    }),
    getManualInsuranceDynamicFields: builder.query<
      Record<string, DynamicFormFieldAttributes>,
      PracticeIdParam
    >({
      query: ({ id }) => {
        return {
          url: `${Base.V3}/practices/${id}/manual_insurance_forms/get_current`,
          method: 'GET',
        };
      },
      providesTags: [Tags.ManualInsuranceDynamicFields],
      transformResponse: supportedInsuranceFormsTransform,
    }),
    getUserSchedulability: builder.query<
      CalendarBlock[],
      UserSchedulabilityParams
    >({
      query: ({ practiceId, providerId, beginDateTime, endDateTime }) => {
        return {
          url: `${Base.V3}/practices/${practiceId}/calendar_blocks`,
          method: 'GET',
          params: {
            user_id: providerId,
            begin_datetime: beginDateTime,
            end_datetime: endDateTime,
          },
        };
      },
      transformResponse: calendarBlockResponseTransform,
      providesTags: [Tags.Schedule],
    }),
    getUserSchedulabilityForPractices: builder.query<
      CalendarBlock[],
      UserSchedulabilityForPracticesParams
    >({
      query: ({
        practiceIds,
        userId,
        beginDateTime,
        endDateTime,
        available,
        schedulable,
        limit,
      }) => {
        return {
          url: `${Base.V3}/calendar_blocks/bulk_for_practices`,
          method: 'GET',
          params: {
            practice_ids: practiceIds,
            user_id: userId,
            begin_datetime: beginDateTime,
            end_datetime: endDateTime,
            available,
            schedulable,
            limit,
            offset: 0,
          },
        };
      },
      transformResponse: calendarBlockResponseTransform,
      providesTags: [Tags.Schedule],
    }),
    addUserCalendarBlock: builder.mutation<
      ScheduleBlocks,
      CreateCalendarBlockParams
    >({
      query: ({ practiceId, calendarBlock }) => {
        return {
          url: `${Base.V3}/practices/${practiceId}/calendar_blocks`,
          method: 'POST',
          body: {
            calendar_block: calendarBlock,
          },
        };
      },
      invalidatesTags: [Tags.Schedule],
    }),
    updateCalendarBlock: builder.mutation<
      ScheduleBlocks,
      UpdateCalendarBlockParams
    >({
      query: ({ practiceId, calendarBlock }) => {
        return {
          url: `${Base.V3}/practices/${practiceId}/calendar_blocks/${calendarBlock.id}`,
          method: 'PUT',
          body: {
            calendar_block: calendarBlock,
          },
        };
      },
      invalidatesTags: [Tags.Schedule],
    }),
    deleteCalendarBlock: builder.mutation<
      ScheduleBlocks,
      DeleteCalendarBlockParams
    >({
      query: ({
        practiceId,
        blockId,
        onlyThisOccurrence,
        dateToRemove,
        dateToRemoveFrom,
      }) => {
        return {
          url: `${Base.V3}/practices/${practiceId}/calendar_blocks/${blockId}`,
          method: 'DELETE',
          params: {
            only_this_occurrence: onlyThisOccurrence,
            date_to_remove_from: dateToRemoveFrom,
            date_to_remove: dateToRemove,
          },
        };
      },
      invalidatesTags: [Tags.Schedule],
    }),
    searchPracticeProviders: builder.query<
      User[],
      SearchPracticeProvidersParams
    >({
      query: ({ practiceId, search, limit = 20 }) => ({
        url: `${Base.V3}/practices/${practiceId}/providers/search`,
        method: 'PUT',
        body: {
          limit,
          offset: 0,
          search_term: search,
        },
      }),
      transformResponse: (response: SearchPracticeProvidersResponse) => {
        const transformed: User[] = _.map(response.data, userResponse => {
          return userTransform({
            data: userResponse,
            included: response.included,
          });
        });
        return transformed;
      },
    }),
    createNewPatient: builder.mutation<
      CreateNewPatientResponse,
      CreateNewPatientParams
    >({
      query: ({
        firstName,
        lastName,
        dob,
        email,
        phone,
        locale,
        isMinor,
        practiceId,
        providerId,
        isDependent,
        address,
        gender,
      }) => {
        const body = {
          first_name: firstName,
          last_name: lastName,
          dob,
          email,
          phone: phone.replaceAll('-', ''),
          locale,
          isYounger18: isMinor,
          practice_id: practiceId,
          provider_id: providerId,
          skip_welcome_email: true,
          is_dependent: isDependent,
          gender,
        };
        const addressIsNotEmpty =
          address && Object.values(address).some(value => !!value);
        if (addressIsNotEmpty) {
          _.update(body, 'address', () => ({
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            zip: address.zip,
            verified: false,
            details: null,
          }));
        }
        return {
          url: `${Base.V2}/practices/${practiceId}/new_patient`,
          method: 'POST',
          body,
        };
      },
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [Tags.SearchPatients],
    }),
    createNewPatientFromCheckIn: builder.mutation<
      CreateNewPatientFromCheckInResponse,
      CreateNewPatientFromCheckInParams
    >({
      query: ({
        firstName,
        lastName,
        dob,
        email,
        phone,
        locale,
        gender,
        line1,
        line2,
        city,
        state,
        zip,
        practiceId,
      }) => {
        return {
          url: `${Base.V2}/practices/${practiceId}/patients`,
          method: 'POST',
          body: {
            practice_id: practiceId,
            first_name: firstName,
            last_name: lastName,
            dob,
            email,
            phone_cell: phone,
            locale,
            gender,
            line1,
            line2,
            city,
            state,
            zip,
          },
        };
      },
      transformResponse: sanitizeAndTransformResponseData,
    }),
    getAppointmentTimes: builder.query<
      AppointmentTimesResponse,
      AppointmentTimesParams
    >({
      query: ({
        practiceId,
        providerId,
        offset,
        beginDate,
        numberOfDays,
        visitTypeId,
      }) => {
        return {
          url: `${Base.V2}/practices/${practiceId}/members/${providerId}/appointment_times`,
          params: {
            offset,
            begin_date: beginDate,
            number_of_days: numberOfDays,
            visit_type_id: visitTypeId,
          },
          method: 'GET',
        };
      },
      providesTags: [Tags.Schedule],
      transformResponse: sanitizeAndTransformResponseData,
    }),
    loadScheduledBlocks: builder.query<
      ScheduleBlocks[],
      LoadScheduledBlocksParams
    >({
      query: ({ practiceId, providerId }) => {
        return {
          url: `${Base.V3}/practices/${practiceId}/providers/${providerId}/schedule_blocks`,
          method: 'GET',
        };
      },
      providesTags: [Tags.Schedule],
      transformResponse: sanitizeAndTransformResponseData,
    }),
    getPracticeServesLocation: builder.query<
      PracticeServesLocationResponse,
      PracticeServesLocationParams
    >({
      query: ({ practiceId, countryName, countryRegion }) => {
        return {
          url: `${Base.V3}/practices/${practiceId}/serves_location`,
          method: 'GET',
          headers: {
            'eVisit-Dev-Tools-Country-Name': countryName,
            'eVisit-Dev-Tools-Country-Region': countryRegion,
          },
        };
      },
      transformResponse: sanitizeAndTransformResponseData,
      providesTags: [Tags.ServesLocation],
    }),
    getPracticeVisitTypes: builder.query<VisitTypeData[], PracticeIdParam>({
      query: ({ id }) => {
        return {
          url: `${Base.V3}/practices/${id}/visit_types`,
          method: 'GET',
        };
      },
      transformResponse: sanitizeAndTransformResponse,
    }),
    getPracticeLanguageQuickSelects: builder.query<
      PracticeLanguageQuickSelectsResponse[],
      PracticeIdParam
    >({
      query: ({ id }) => {
        return {
          url: `${Base.V3}/practices/${id}/language_quick_selects`,
          method: 'GET',
        };
      },
      transformResponse: sanitizeAndTransformResponse,
    }),
    getPracticeTradingPartners: builder.query<
      PracticeTradingPartnerResponse[],
      PracticeIdParam
    >({
      query: ({ id }) => {
        return {
          url: `${Base.V4}/practices/${id}/trading_partners`,
          method: 'GET',
        };
      },
      transformResponse: sanitizeAndTransformResponse,
    }),
    getPracticeInsurancePlans: builder.query<
      PracticeInsurancePlan[],
      PracticeIdParam
    >({
      query: ({ id }) => {
        return {
          url: `${Base.V3}/practices/${id}/practice_insurance_plans`,
          method: 'GET',
        };
      },
      transformResponse: sanitizeAndTransformInsurancePlansResponse,
    }),
  }),
});

export const {
  useUpdatePracticeMutation,
  useGetPracticeByHandleQuery,
  useGetPracticesByIdQuery,
  useLazyGetPracticeByIdQuery,
  useGetPracticeCustomVerbiageQuery,
  useGetPracticeDevicesQuery,
  useLazyGetVisitLinkForDeviceQuery,
  useCreatePracticeDeviceMutation,
  useSetDeviceToInVisitMutation,
  useUpdatePracticeDeviceMutation,
  useDeletePracticeDeviceMutation,
  useGetManualInsuranceDynamicFieldsQuery,
  useGetSupportedInsuranceDynamicFieldsQuery,
  useGetUserSchedulabilityQuery,
  useGetUserSchedulabilityForPracticesQuery,
  useAddUserCalendarBlockMutation,
  useUpdateCalendarBlockMutation,
  useDeleteCalendarBlockMutation,
  useAssignPracticeDeviceMutation,
  useLaunchPracticeDeviceMutation,
  useLogoutPracticeDeviceMutation,
  useSearchPracticeProvidersQuery,
  useCreateNewPatientMutation,
  useCreateNewPatientFromCheckInMutation,
  useGetAppointmentTimesQuery,
  useLazyGetAppointmentTimesQuery,
  useGetPracticeServesLocationQuery,
  useGetPracticeVisitTypesQuery,
  useGetPracticeLanguageQuickSelectsQuery,
  useGetPracticeTradingPartnersQuery,
  useLazyGetPracticeTradingPartnersQuery,
  useGetPracticeInsurancePlansQuery,
} = practicesApi;
