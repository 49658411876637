import _ from 'lodash';
import { DateTime } from 'luxon';

import {
  AllowAttendees,
  BeginImpendingVisit,
  CancelVisit,
  CompleteVisit,
  CondensedVisits,
  InitiateVisit,
  JoinVisit,
  MedicationResult,
  ScheduleVisit,
  SearchVisitsResult,
  SubmitVisit,
  VisitParticipant,
  VisitWithPatient,
} from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { SuccessResponse } from 'ev-api/common/SuccessResponse';
import {
  sanitizeAndTransformResponse,
  sanitizeAndTransformResponseData,
} from 'ev-api/common/transformers';
import {
  ConfirmScheduledVisitParams,
  CoSignVisitResponse,
  CreatedPrescriptions,
  CreatePrescriptionParams,
  CreateVisitResponse,
  DeletePrescriptionParams,
  GetAvailableStatusesResponse,
  GetGuestShareableTokenResponse,
  GetVisitPrescriptionParams,
  OpenTextNotes,
  ReassignVisitResponse,
  SavePrescriptionParams,
  ScheduleVisitParams,
  schedulingApi,
  SearchWaitingRoomVisitsResponse,
  SearchWaitingRoomVisitsResponseWithData,
  SendPrescriptionParams,
  SendPrescriptionResults,
  ShareableVisitLinkResponse,
  SignVisitResponse,
  SubmitVisitParams,
  UpdateRequiresCoSignResponse,
  UpdateVisitResponse,
  VisitFeedbackResponse,
  VisitPretestResponse,
} from 'ev-api/core';
import { clientDateFormatter } from 'ev-utils/formatters';

import {
  AddVisitNotesParams,
  AllowAttendeesParams,
  AttachmentUploadParams,
  BeginImpendingVisitParams,
  CancelVisitParams,
  ChargeVisitParams,
  CompleteVisitParams,
  CoSignVisitParams,
  CreateVisitParams,
  GetProviderVisitsHistoryParams,
  GetVisitByIdParams,
  GetVisitNotesParams,
  GetVisitParticipantsParams,
  GetVisitsParams,
  InitiateVisitParams,
  JoinCopyLinkParams,
  JoinVisitParams,
  ReassignVisitParams,
  RemoveAttachmentParams,
  RemoveParticipantParams,
  ResendInviteParams,
  SaveVisitStatsParams,
  SaveVisitSurveyParams,
  SearchVisitsParams,
  SearchWaitingRoomVisitsParams,
  SetPatientLocationOverrideParams,
  ShareableVisitLinkParams,
  SignVisitParams,
  StartVisitParams,
  UpdateReasonForNoChargeParams,
  UpdateRequiresCoSignParams,
  UpdateUnscheduledVisitParams,
  UpdateVisitParams,
  UpdateVisitScheduledDateParams,
  VisitFeedbackParams,
  VisitIdParam,
  VisitOutcomeParams,
  VisitPretestParams,
  VisitServesLocationParams,
  VisitServesLocationResponse,
  VisitTermsParams,
} from './params';
import {
  createPrescriptionTransformer,
  getVisitParticipantsTransformer,
  getVisitPrescriptionTransformer,
  getVisitsTransformer,
  searchVisitsTransformer,
  sendPrescriptionTransformer,
  visitByIdTransformer,
} from './transformers';

export const visitsApi = api.injectEndpoints({
  endpoints: builder => ({
    createVisit: builder.mutation<CreateVisitResponse, CreateVisitParams>({
      query: ({
        date,
        estimated_duration_in_minutes,
        firstAvailable = false,
        health_doc,
        kind = 'default',
        patientId,
        patientLocation,
        practiceId,
        providerId,
        providerGroupId,
        requestQuestions,
        state = 'submitted',
        time,
        visitTypeId,
        price,
        edataPack,
      }) => ({
        url: `${Base.V2}/visits.json`,
        method: 'POST',
        body: {
          date,
          estimated_duration_in_minutes,
          aasm_state: state,
          attachments: [],
          health_doc: health_doc,
          insurance: {},
          kind,
          patient_id: patientId,
          patient_location: patientLocation,
          practice_id: practiceId,
          preferred_physician_id: providerId,
          provider_group_id: providerGroupId,
          prefers_first_available: firstAvailable,
          request_questions: requestQuestions,
          visit_type_id: visitTypeId,
          time,
          copay: price || '0',
          edata_pack: edataPack,
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [Tags.Visits],
    }),
    updateUnscheduledVisit: builder.mutation<
      void,
      UpdateUnscheduledVisitParams
    >({
      query: ({ date, estimated_duration_in_minutes, time, visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/update_unscheduled_visit_scheduled_at`,
        method: 'POST',
        body: {
          date,
          estimated_duration_in_minutes,
          id: visitId,
          time,
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: (_result, _error, args) =>
        args.skipTagsInvalidation ? [] : [Tags.Visits],
    }),
    joinVisit: builder.mutation<JoinVisit, JoinVisitParams>({
      query: ({ visitId, memberId, email, token, first_name, last_name }) => ({
        url: `${Base.V3}/visits/${visitId}/attendees/${memberId}/join`,
        method: 'POST',
        body: { email, token, first_name, last_name },
      }),
      transformResponse: sanitizeAndTransformResponseData,
    }),
    joinVisitFromCopyLink: builder.mutation<JoinVisit, JoinCopyLinkParams>({
      query: ({ visitId, token, first_name, last_name }) => ({
        url: `${Base.V3}/visits/${visitId}/attendees/join_with_guest_shareable_token`,
        method: 'POST',
        body: { token, first_name, last_name },
      }),
      transformResponse: sanitizeAndTransformResponseData,
    }),
    // Disconnects and denies access
    removeParticipant: builder.mutation<void, RemoveParticipantParams>({
      query: ({ visitId, attendeeId, identity }) => ({
        url: `${Base.V3}/visits/${visitId}/attendees/${attendeeId}`,
        method: 'DELETE',
        body: { identity },
      }),
    }),
    // Disconnects but does not deny access
    disconnectParticipant: builder.mutation<void, RemoveParticipantParams>({
      query: ({ visitId, attendeeId, identity }) => ({
        url: `${Base.V3}/visits/${visitId}/attendees/${attendeeId}/disconnect`,
        method: 'PUT',
        body: { identity },
      }),
    }),
    cancelVisit: builder.mutation<CancelVisit, CancelVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/transition/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Visits, Tags.User],
    }),
    cancelIncompleteVisit: builder.mutation<CancelVisit, CancelVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/cancel_visit`,
        method: 'POST',
      }),
      invalidatesTags: [Tags.Visits],
    }),
    initiateVisit: builder.mutation<InitiateVisit, InitiateVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/transition/initiate`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Visits, Tags.User],
    }),
    completeVisit: builder.mutation<CompleteVisit, CompleteVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/transition/end`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Visits, Tags.VisitParticipants],
    }),
    submitVisit: builder.mutation<SubmitVisit, SubmitVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/transition/submit`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Visits, Tags.User],
    }),
    scheduleVisit: builder.mutation<ScheduleVisit, ScheduleVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/transition/schedule`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Visits],
    }),
    allowAttendees: builder.mutation<AllowAttendees, AllowAttendeesParams>({
      query: ({ visitId, memberId }) => ({
        url: `${Base.V3}/visits/${visitId}/attendees/${memberId}/allow`,
        method: 'POST',
      }),
      transformResponse: sanitizeAndTransformResponseData,
    }),
    beginImpendingVisit: builder.mutation<
      BeginImpendingVisit,
      BeginImpendingVisitParams
    >({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/transition/begin_impending`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.User],
    }),
    updateVisit: builder.mutation<UpdateVisitResponse, UpdateVisitParams>({
      query: ({ visitId, patch }) => ({
        url: `${Base.V3}/visits/${visitId}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: [Tags.Visits],
    }),
    getVisitById: builder.query<VisitWithPatient, GetVisitByIdParams>({
      query: ({ visitId }) => ({
        url: `${Base.V3}/visits/${visitId}`,
        method: 'GET',
      }),
      providesTags: [Tags.Visits, Tags.User],
      transformResponse: visitByIdTransformer,
    }),
    searchVisits: builder.query<SearchVisitsResult, SearchVisitsParams>({
      query: ({
        segmentId = '1',
        status = 'upcoming',
        fields,
        filterByProvider = null,
        offset = 0,
        limit = 20,
        patientStatus,
        providerIds = [],
        search,
      }) => {
        const patient_statuses = [];
        if (patientStatus?.arrived) {
          patient_statuses.push('arrived');
        }

        if (patientStatus?.ready) {
          patient_statuses.push('ready');
        }

        return {
          url: `${Base.V3}/visits/search`,
          method: 'POST',
          body: {
            filter_by_provider: filterByProvider,
            fields: { visit: fields },
            segment_id: segmentId,
            patient_statuses,
            provider_ids: providerIds,
            offset,
            search,
            status,
            limit,
          },
        };
      },
      providesTags: [Tags.Visits],
      transformResponse: searchVisitsTransformer,
    }),
    getVisits: builder.query<CondensedVisits, GetVisitsParams>({
      query: ({
        patientId,
        status,
        limit,
        offset,
        kind,
        startDate = '',
        endDate = '',
        search,
        providerIds,
        actionsRequired,
        listLite,
        filterByProvider,
      }) => ({
        url: `${Base.V3}/visits`,
        params: {
          status: _.join(status, ','),
          patient_id: patientId,
          start_date: clientDateFormatter(startDate),
          end_date: clientDateFormatter(endDate),
          search: search,
          provider_ids: providerIds,
          actions_required: actionsRequired,
          list_lite: listLite,
          filter_by_provider: filterByProvider,
          kind,
          limit,
          offset,
        },
      }),
      providesTags: [Tags.Visits],
      transformResponse: getVisitsTransformer,
    }),
    getVisitParticipants: builder.query<
      VisitParticipant[],
      GetVisitParticipantsParams
    >({
      query: ({ visitId }) => ({
        url: `${Base.V3}/visits/${visitId}/participants`,
      }),
      transformResponse: getVisitParticipantsTransformer,
      providesTags: [Tags.VisitParticipants],
    }),
    searchWaitingRoomVisits: builder.query<
      SearchWaitingRoomVisitsResponse,
      SearchWaitingRoomVisitsParams
    >({
      query: ({
        segmentId,
        limit,
        offset,
        patientStatuses,
        providerIds,
        searchTerm,
        onlyTotal,
        sortBy,
        filters,
        complexSortingAndFiltersEnabled,
      }) => {
        const request = {
          segment_id: segmentId,
          patient_statuses: patientStatuses,
          provider_ids: providerIds,
          search_term: searchTerm,
          only_total: onlyTotal,
          sort_by: sortBy,
          limit,
          offset,
          filters,
        };
        return complexSortingAndFiltersEnabled
          ? {
              // use old or new endpoint according to FF
              url: `${Base.V4}/waiting_room/search`,
              method: 'POST',
              body: request,
            }
          : {
              url: `${Base.V4}/waiting_room/search_visits`,
              params: request,
            };
      },
      providesTags: [Tags.Visits, Tags.CustomNotes],
    }),
    startVisit: builder.mutation<SuccessResponse, StartVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V4}/visits_room/${visitId}/start_visit`,
        method: 'POST',
        params: { id: visitId },
      }),
      transformResponse: sanitizeAndTransformResponse,
    }),
    generateShareableVisitLink: builder.mutation<
      ShareableVisitLinkResponse,
      ShareableVisitLinkParams
    >({
      query: ({ visitId }) => ({
        url: `${Base.V4}/visits_room/${visitId}/share`,
        method: 'POST',
        params: { id: visitId },
      }),
      transformResponse: sanitizeAndTransformResponse,
    }),
    resendVisitInvite: builder.mutation<SuccessResponse, ResendInviteParams>({
      query: ({ visitId, attendeeId, kind }) => ({
        url: `${Base.V4}/visits_room/${visitId}/attendees/${attendeeId}/notify`,
        method: 'POST',
        body: {
          attendee_id: attendeeId,
          kind,
          id: visitId,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
    }),
    reassignVisit: builder.mutation<ReassignVisitResponse, ReassignVisitParams>(
      {
        query: ({
          visitId,
          providerId,
          providerGroupId,
          patientLocation,
          statePrecondition,
        }) => ({
          url: `${Base.V2}/visits/${visitId}/reassign_visit_preferred_physician`,
          method: 'PUT',
          body: {
            preferred_physician_id: providerId,
            provider_group_id: providerGroupId,
            patient_location: patientLocation,
            state_precondition: statePrecondition,
          },
        }),
        invalidatesTags: [Tags.Visits],
      },
    ),
    attachmentUpload: builder.mutation<SuccessResponse, AttachmentUploadParams>(
      {
        query: ({ userId, visitId, blobId, filename }) => ({
          url: `${Base.V2}/visits/${visitId}/attachment_upload`,
          method: 'POST',
          body: {
            uid: userId,
            blob_id: blobId,
            filename,
          },
        }),
        invalidatesTags: [Tags.Visits],
      },
    ),
    removeAttachment: builder.mutation<SuccessResponse, RemoveAttachmentParams>(
      {
        query: ({ attachmentId, visitId }) => ({
          url: `${Base.V2}/visits/${visitId}/remove_attachment`,
          method: 'POST',
          body: {
            attachment_blob_id: attachmentId,
          },
        }),
        invalidatesTags: [Tags.Visits],
      },
    ),
    createPrescription: builder.mutation<
      CreatedPrescriptions,
      CreatePrescriptionParams
    >({
      query: ({
        practiceId,
        patientId,
        visitId,
        medication,
        patientMetrics,
      }) => ({
        url: `${Base.V2}/practices/${practiceId}/patients/${patientId}/visits/${visitId}/prescriptions`,
        method: 'POST',
        body: {
          prescriptions: [{ medication }],
          ...patientMetrics,
        },
      }),
      transformResponse: createPrescriptionTransformer,
      invalidatesTags: [Tags.VisitPrescriptions],
    }),
    savePrescription: builder.mutation<SuccessResponse, SavePrescriptionParams>(
      {
        query: ({ practiceId, patientId, visitId, prescription }) => ({
          url: `${Base.V2}/practices/${practiceId}/patients/${patientId}/visits/${visitId}/prescriptions/save_prescription`,
          method: 'POST',
          body: {
            prescription,
          },
        }),
        invalidatesTags: [Tags.VisitPrescriptions],
      },
    ),
    deletePrescription: builder.mutation<
      SuccessResponse,
      DeletePrescriptionParams
    >({
      query: ({ practiceId, patientId, visitId, prescriptionId }) => ({
        url: `${Base.V2}/practices/${practiceId}/patients/${patientId}/visits/${visitId}/prescriptions/delete_prescription`,
        method: 'POST',
        body: {
          prescription_id: prescriptionId,
        },
      }),
      invalidatesTags: [Tags.VisitPrescriptions],
    }),
    sendPrescription: builder.mutation<
      SendPrescriptionResults[],
      SendPrescriptionParams
    >({
      query: ({ practiceId, patientId, visitId, prescriptionIds }) => ({
        url: `${Base.V2}/practices/${practiceId}/patients/${patientId}/visits/${visitId}/prescriptions/send_prescriptions`,
        method: 'POST',
        body: {
          dosespot_prescription_ids: prescriptionIds,
        },
      }),
      transformResponse: sendPrescriptionTransformer,
      invalidatesTags: [Tags.VisitPrescriptions],
    }),
    getVisitPrescriptions: builder.query<
      MedicationResult[],
      GetVisitPrescriptionParams
    >({
      query: ({ practiceId, patientId, visitId }) => ({
        url: `${Base.V2}/practices/${practiceId}/patients/${patientId}/visits/${visitId}/prescriptions`,
      }),
      transformResponse: getVisitPrescriptionTransformer,
      providesTags: [Tags.VisitPrescriptions],
    }),
    chargeVisit: builder.mutation<SuccessResponse, ChargeVisitParams>({
      query: ({ visitId, visit }) => ({
        url: `${Base.V2}/visits/${visitId}/charge`,
        method: 'POST',
        body: {
          visit,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
      invalidatesTags: [Tags.Visits],
    }),
    updateReasonForNoCharge: builder.mutation<
      SuccessResponse,
      UpdateReasonForNoChargeParams
    >({
      query: ({ visitId, reason }) => ({
        url: `${Base.V2}/visits/${visitId}/set_reason_for_no_charge`,
        method: 'PUT',
        body: {
          reason,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
      invalidatesTags: [Tags.Visits],
    }),
    signVisit: builder.mutation<SignVisitResponse, SignVisitParams>({
      query: ({ visitId, operation }) => ({
        url: `${Base.V2}/visits/${visitId}/sign`,
        method: 'POST',
        body: {
          operation,
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [Tags.Visits],
    }),
    coSignVisit: builder.mutation<CoSignVisitResponse, CoSignVisitParams>({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/cosign`,
        method: 'POST',
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [Tags.Visits],
    }),
    updateRequiresCosign: builder.mutation<
      UpdateRequiresCoSignResponse,
      UpdateRequiresCoSignParams
    >({
      query: ({ visitId, requiresCosign }) => ({
        url: `${Base.V3}/visits/${visitId}/update_requires_cosign`,
        method: 'PUT',
        body: {
          requires_cosign: requiresCosign,
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [Tags.Visits],
    }),
    visitFeedback: builder.mutation<VisitFeedbackResponse, VisitFeedbackParams>(
      {
        query: ({ visitId, video, audio, connectivity, other, message }) => ({
          url: `${Base.V3}/visits/${visitId}/feedback`,
          method: 'POST',
          body: {
            video: video,
            audio: audio,
            connectivity: connectivity,
            other: other,
            message: message,
          },
        }),
      },
    ),
    visitPretest: builder.mutation<VisitPretestResponse, VisitPretestParams>({
      query: ({ visitId, client }) => ({
        url: `${Base.V3}/visits/${visitId}/pretest`,
        method: 'PUT',
        body: {
          client: client,
        },
      }),
    }),
    visitOutcome: builder.mutation<SuccessResponse, VisitOutcomeParams>({
      query: ({ visitId, success, previsitArrivalId }) => ({
        url: `${Base.V3}/visits/${visitId}/pretest/${previsitArrivalId}/outcome`,
        method: 'POST',
        body: {
          success: success,
        },
      }),
    }),
    createVisitTermsAgreement: builder.mutation<
      SuccessResponse,
      VisitTermsParams
    >({
      query: ({ visitId, terms }) => ({
        url: `${Base.V2}/visits/${visitId}/create_terms_agreement`,
        method: 'POST',
        body: {
          terms,
        },
      }),
    }),
    updateVisitScheduledDate: builder.mutation<
      SuccessResponse,
      UpdateVisitScheduledDateParams
    >({
      query: ({
        visitId,
        scheduledAt,
        estimatedDurationInMinutes,
        externalAppointmentId,
      }) => ({
        url: `${Base.V2}/visits/${visitId}/update_scheduled_visit_date`,
        method: 'POST',
        body: {
          id: visitId,
          date: scheduledAt.toISODate(),
          time: scheduledAt.toISOTime(),
          estimated_duration_in_minutes: estimatedDurationInMinutes,
          external_appointment_id: externalAppointmentId,
        },
      }),
      invalidatesTags: (_result, _error, args) =>
        args.skipTagsInvalidation ? [] : [Tags.Visits],
    }),
    confirmScheduledVisit: builder.mutation<
      SuccessResponse,
      ConfirmScheduledVisitParams
    >({
      query: ({ visitId }) => ({
        url: `${Base.V2}/visits/${visitId}/confirm_scheduled_visit`,
        method: 'POST',
      }),
      invalidatesTags: [Tags.Visits],
    }),
    getVisitServesLocation: builder.query<
      VisitServesLocationResponse,
      VisitServesLocationParams
    >({
      query: ({ visitId, countryName, countryRegion }) => {
        return {
          url: `${Base.V3}/visits/${visitId}/serves_location`,
          method: 'GET',
          headers: {
            'eVisit-Dev-Tools-Country-Name': countryName,
            'eVisit-Dev-Tools-Country-Region': countryRegion,
          },
        };
      },
      transformResponse: sanitizeAndTransformResponseData,
      providesTags: [Tags.ServesLocation],
    }),
    saveVisitSurvey: builder.mutation<SuccessResponse, SaveVisitSurveyParams>({
      query: ({ visitId, visitSurveyId, data }) => ({
        url: `${Base.V2}/visits/${visitId}/rate`,
        method: 'POST',
        body: {
          visit_survey: {
            visit_id: visitId,
            visit_survey_id: visitSurveyId,
            data,
          },
        },
      }),
      invalidatesTags: [Tags.Visits],
    }),
    getProviderVisitsHistory: builder.query<
      CondensedVisits,
      GetProviderVisitsHistoryParams
    >({
      query: ({
        patientId,
        status,
        limit,
        offset,
        kind,
        startDate = '',
        endDate = '',
        search,
        providerIds,
        actionsRequired,
        listLite,
        filterByProvider,
        sortBy,
        filters,
      }) => ({
        url: `${Base.V3}/visits/providers_history`,
        method: 'POST',
        body: {
          status: _.join(status, ','),
          patient_id: patientId,
          start_date: clientDateFormatter(startDate),
          end_date: clientDateFormatter(endDate),
          search: search,
          provider_ids: providerIds,
          actions_required: actionsRequired,
          list_lite: listLite,
          filter_by_provider: filterByProvider,
          kind,
          limit,
          offset,
          sort_by: sortBy,
          filters,
        },
      }),
      providesTags: [Tags.Visits, Tags.CustomNotes],
      transformResponse: getVisitsTransformer,
    }),
    setPatientLocationOverride: builder.mutation<
      void,
      SetPatientLocationOverrideParams
    >({
      query: ({ visitId, stateCode }) => ({
        url: `${Base.V4}/visits/${visitId}/set_patient_location_override`,
        method: 'PATCH',
        body: {
          state_code: stateCode,
        },
      }),
    }),
    saveVisitStats: builder.mutation<void, SaveVisitStatsParams>({
      query: ({ visitId, icdCodes, cptCodes }) => ({
        url: `${Base.V3}/visits/${visitId}/stats`,
        method: 'POST',
        body: _.omitBy(
          {
            icd_codes: icdCodes,
            cpt_codes: cptCodes,
          },
          _.isNil,
        ),
      }),
    }),
    addVisitNotes: builder.mutation<void, AddVisitNotesParams>({
      query: ({ visitId, columnId, value }) => ({
        url: `${Base.V4}/visits/${visitId}/note`,
        method: 'POST',
        body: {
          field: columnId,
          value,
        },
      }),
      async onQueryStarted(
        { visitId, value, author },
        { dispatch, queryFulfilled, getState },
      ) {
        let patchResult;
        for (const {
          endpointName,
          originalArgs,
        } of api.util.selectInvalidatedBy(getState(), [Tags.CustomNotes])) {
          const updatedNote = {
            note: value,
            author,
            date: DateTime.now().toISO(),
          };
          if (endpointName === 'getScheduledVisits') {
            patchResult = dispatch(
              schedulingApi.util.updateQueryData(
                endpointName,
                originalArgs,
                draft => {
                  draft.visits.map(v => {
                    if (v.id === visitId) {
                      v.attributes.open_text_notes = updatedNote;
                    }
                    return v;
                  });
                },
              ),
            );
          } else if (endpointName === 'getProviderVisitsHistory') {
            patchResult = dispatch(
              visitsApi.util.updateQueryData(
                endpointName,
                originalArgs,
                draft => {
                  draft.visits.map(v => {
                    if (v.id === visitId) {
                      v.attributes.open_text_notes = updatedNote;
                    }
                    return v;
                  });
                },
              ),
            );
          } else if (endpointName === 'searchWaitingRoomVisits') {
            patchResult = dispatch(
              visitsApi.util.updateQueryData(
                endpointName,
                originalArgs,
                draft => {
                  const parsedDraft = (
                    draft as SearchWaitingRoomVisitsResponseWithData
                  ).data;
                  if (parsedDraft.length) {
                    parsedDraft.map(v => {
                      if (v.id === visitId) {
                        v.open_text_notes = updatedNote;
                      }
                      return v;
                    });
                  }
                },
              ),
            );
          }
        }
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    getVisitNotes: builder.query<OpenTextNotes, GetVisitNotesParams>({
      query: ({ visitId }) => ({
        url: `${Base.V4}/visits/${visitId}/note`,
      }),
      async onQueryStarted(
        { visitId },
        { dispatch, queryFulfilled, getState },
      ) {
        let patchResult;
        for (const {
          endpointName,
          originalArgs,
        } of api.util.selectInvalidatedBy(getState(), [Tags.CustomNotes])) {
          const updatedNote = (await queryFulfilled).data;
          if (endpointName === 'getScheduledVisits') {
            patchResult = dispatch(
              schedulingApi.util.updateQueryData(
                endpointName,
                originalArgs,
                draft => {
                  draft.visits.map(v => {
                    if (v.id === visitId) {
                      v.attributes.open_text_notes = updatedNote;
                    }
                    return v;
                  });
                },
              ),
            );
          } else if (endpointName === 'getProviderVisitsHistory') {
            patchResult = dispatch(
              visitsApi.util.updateQueryData(
                endpointName,
                originalArgs,
                draft => {
                  draft.visits.map(v => {
                    if (v.id === visitId) {
                      v.attributes.open_text_notes = updatedNote;
                    }
                    return v;
                  });
                },
              ),
            );
          } else if (endpointName === 'searchWaitingRoomVisits') {
            patchResult = dispatch(
              visitsApi.util.updateQueryData(
                endpointName,
                originalArgs,
                draft => {
                  const parsedDraft = (
                    draft as SearchWaitingRoomVisitsResponseWithData
                  ).data;
                  if (parsedDraft.length) {
                    parsedDraft.map(v => {
                      if (v.id === visitId) {
                        v.open_text_notes = updatedNote;
                      }
                      return v;
                    });
                  }
                },
              ),
            );
          }
        }
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    getAvailableStatuses: builder.query<GetAvailableStatusesResponse, void>({
      query: () => ({
        url: `${Base.V3}/visits/available_statuses`,
      }),
    }),
    getGuestShareableToken: builder.query<
      GetGuestShareableTokenResponse,
      VisitIdParam
    >({
      query: ({ visitId }) => ({
        url: `${Base.V4}/visits/${visitId}/guest_shareable_token`,
      }),
    }),
    mediaPermissionDenied: builder.mutation<void, VisitIdParam>({
      query: ({ visitId }) => ({
        url: `${Base.V3}/visits/${visitId}/media_permission_denied`,
      }),
    }),
    userSawExtendedWaitMessage: builder.mutation<void, VisitIdParam>({
      query: ({ visitId }) => ({
        url: `${Base.V3}/visits/${visitId}/user_saw_extended_wait_message`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCreateVisitMutation,
  useGetVisitsQuery,
  useJoinVisitMutation,
  useJoinVisitFromCopyLinkMutation,
  useRemoveParticipantMutation,
  useDisconnectParticipantMutation,
  useGetVisitByIdQuery,
  useLazyGetVisitByIdQuery,
  useAllowAttendeesMutation,
  useCancelVisitMutation,
  useCancelIncompleteVisitMutation,
  useInitiateVisitMutation,
  useCompleteVisitMutation,
  useBeginImpendingVisitMutation,
  useSearchWaitingRoomVisitsQuery,
  useResendVisitInviteMutation,
  useGenerateShareableVisitLinkMutation,
  useReassignVisitMutation,
  useStartVisitMutation,
  useUpdateVisitMutation,
  useGetVisitParticipantsQuery,
  useRemoveAttachmentMutation,
  useAttachmentUploadMutation,
  useCreatePrescriptionMutation,
  useSavePrescriptionMutation,
  useDeletePrescriptionMutation,
  useSendPrescriptionMutation,
  useGetVisitPrescriptionsQuery,
  useChargeVisitMutation,
  useUpdateReasonForNoChargeMutation,
  useSignVisitMutation,
  useCoSignVisitMutation,
  useUpdateRequiresCosignMutation,
  useVisitFeedbackMutation,
  useVisitPretestMutation,
  useVisitOutcomeMutation,
  useCreateVisitTermsAgreementMutation,
  useUpdateVisitScheduledDateMutation,
  useConfirmScheduledVisitMutation,
  useScheduleVisitMutation,
  useSubmitVisitMutation,
  useGetVisitServesLocationQuery,
  useSaveVisitSurveyMutation,
  useUpdateUnscheduledVisitMutation,
  useGetProviderVisitsHistoryQuery,
  useSetPatientLocationOverrideMutation,
  useSaveVisitStatsMutation,
  useAddVisitNotesMutation,
  useLazyGetVisitNotesQuery,
  useGetAvailableStatusesQuery,
  useLazyGetGuestShareableTokenQuery,
  useMediaPermissionDeniedMutation,
  useUserSawExtendedWaitMessageMutation,
} = visitsApi;
