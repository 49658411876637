import { Id, Timestamp } from 'ev-types';

export enum FieldType {
  ShortText = 'short_text',
  LongText = 'long_text',
  SingleSelection = 'single_selection',
  MultiSelection = 'multi_selection',
  Date = 'date_picker',
  DateTime = 'date_time',
  Email = 'email',
  Phone = 'phone',
  Number = 'number',
  Boolean = 'boolean',
  Dropdown = 'dropdown',
  Agreement = 'agreement',
}

export type CustomFormSubscriptionCounts = {
  VisitType: number;
  Practice?: number;
};

export type CustomFormSubscription = {
  id: Id;
  custom_form_id: Id;
  subscriber_type: 'VisitType' | 'Practice' | string;
  subscriber_id: Id;
  event_trigger: 'create_a_visit' | string;
  is_active: boolean;
};

export type CustomFormFieldDatasetItem = {
  value: string;
  conditional_logic?: Array<{ value: string }>;
};

export type CustomFormFieldDataset = CustomFormFieldDatasetItem[] | null;

export type CustomFormField = {
  label: string;
  field_type: FieldType;
  dataset: CustomFormFieldDataset;
  description: string | null;
  hint: string | null;
  id: Id;
  custom_form_id: Id;
  edata_definition_id: Id;
  is_visible: boolean;
  is_required: boolean;
  is_editable: boolean;
  is_archived: boolean;
  is_sensitive: boolean;
  config_options: {
    show_description?: boolean;
    conditional_logic?: string;
    other_option?: boolean;
  } | null;
  filtered_conditional_logic?: string;
  config_validations: Record<string, string> | null;
  sort: number;
  row_sort: number;
  value: string | null;
  data_type: string;
  default_value: string | null;
  created_at: Timestamp;
  updated_at: Timestamp;
  edata_definition: {
    label: string;
    field_type: FieldType;
    dataset: string[] | null;
    description: string | null;
    created_by_user_name: string | null;
    created_at: Timestamp;
    name: string;
  };
  system_datapoint?: string | null;
  field_conditioned_by?: Array<{ id: Id; value: string }> | null;
};

export type CustomForm = {
  id: Id;
  uuid: string;
  customer_id: Id;
  title: string;
  description: string;
  original_form_id: Id | null;
  is_archived: boolean;
  unsaved_data: boolean | null;
  created_at: Timestamp;
  updated_at: Timestamp;
  subscription_counts?: CustomFormSubscriptionCounts;
  fields: CustomFormField[];
  is_published: boolean;
  subscriptions: CustomFormSubscription[];
  config_options: { access: 'provider-shared' | 'provider-only' } | null;
};
